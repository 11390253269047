import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import {
  IonContent,
  IonSearchbar,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { ApiService } from "../../services/api.service";
import { RemindersService } from "../../services/reminders.service";
import { IframeService } from "../../services/iframe.service";

const steps = ["Suburb", "Street", "Property"];
const localityStep = 0;
const streetStep = 1;
const propertyStep = 2;

@Component({
  selector: "app-address",
  templateUrl: "./address.page.html",
  styleUrls: ["./address.page.scss"],
})
export class AddressPage implements OnInit, AfterViewInit {
  @Input() public defaultHref: string;
  @ViewChild(IonSearchbar) private searchBar: IonSearchbar;
  @ViewChild(IonContent) private content: IonContent;
  public options: any;
  public title: string;
  private progress = 0;
  private locality: any;
  private street: any;
  private property: any;
  private baseOptions: any;

  constructor(
    private api: ApiService,
    private loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    private reminders: RemindersService,
    public iframeService: IframeService
  ) {}

  public ngOnInit(): void {
    FirebaseAnalytics.setScreenName({
      screenName: "Address Search",
      nameOverride: "AddressPage",
    });
  }

  public ngAfterViewInit(): void {
    this.populateOptions();
  }

  public dismiss() {
    if (!this.property) {
      return;
    }

    this.modalCtrl.dismiss({
      property: this.property,
    });

    if (!this.iframeService.inIframe()) {
      this.reminders.destroyAllReminders();
    }
  }

  public getOptions(event: any): any {
    this.options = this.baseOptions;
    const value = event.target.value;
    if (value && value.trim() !== "") {
      this.options = this.options.filter(
        (option) => option.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    }
  }

  public selectOption(option): void {
    this.searchBar.value = "";
    switch (this.progress) {
      case localityStep:
        this.locality = option;
        this.progress += 1;
        this.populateOptions();
        break;
      case streetStep:
        this.street = option;
        this.progress += 1;
        this.populateOptions();
        break;
      case propertyStep:
        this.property = option;
        this.dismiss();
        break;
    }
  }

  private async populateOptions(): Promise<void> {
    const loader = await this.loadingCtrl.create();
    loader.present();
    await this.content.scrollToTop(300);
    switch (this.progress) {
      case localityStep:
        this.api.getLocalities().subscribe({
          next: (localities) => {
            this.options = localities;
            this.baseOptions = localities;
            loader.dismiss();
          },
          error: () => loader.dismiss(),
        });
        this.setTitle();
        break;
      case streetStep:
        this.api.getStreets(this.locality.id).subscribe({
          next: (streets) => {
            this.options = streets;
            this.baseOptions = streets;
            loader.dismiss();
          },
          error: () => loader.dismiss(),
        });
        this.setTitle();
        break;
      case propertyStep:
        this.api.getProperties(this.street.id).subscribe({
          next: (properties) => {
            this.options = properties;
            this.baseOptions = properties;
            loader.dismiss();
          },
          error: () => loader.dismiss(),
        });
        this.setTitle();
        break;
    }
  }

  private setTitle(): void {
    this.title = steps[this.progress];
  }
}
