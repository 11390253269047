export const common = {
  council: "south-burnett",
  apiToken: "f2fb38e1765d704bfd8c0fe86e59207d",
  oneSignalAppID: "4624dc78-bda3-43d2-b511-5f1d17133a4d",
  googleSenderID: "19875509330",
  googleApiKey: "AIzaSyB2tnceYO-jRKYlKGWV4Uhbixyhvwl4Ln0",
  appVersion: "1.0.2.0",
  googleDirectionsUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  vouchersInfoId: 45,
  defaultPageSize: 50,
  mapMarkerPath:
    "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
  firebaseConfig: {
    apiKey: "AIzaSyB2tnceYO-jRKYlKGWV4Uhbixyhvwl4Ln0",
    authDomain: "sbrc-waste-info.firebaseapp.com",
    projectId: "sbrc-waste-info",
    storageBucket: "sbrc-waste-info.appspot.com",
    messagingSenderId: "340602051550",
    appId: "1:340602051550:web:69ecb5bac34a9351625b4f",
    measurementId: "G-Y8F8MPNMDL"
  },
  pages: [
    {
      title: "Calendar",
      icon: "calendar",
      colour: "secondary",
      route: "/calendar",
    },
    {
      title: "A-Z of Waste",
      icon: "a-z",
      colour: "secondary",
      route: "/a-z",
    },
    {
      title: "Bin Info",
      icon: "bin",
      colour: "secondary",
      route: "/bin-info",
    },
    {
      title: "Forms",
      icon: "forms",
      colour: "secondary",
      route: "/info/2",
      params: { title: "Forms" },
    },
    {
      title: "Recycling Near Me",
      icon: "recycling-near-me",
      colour: "secondary",
      route: "/info-multi-map/42",
    },
    // {
    //   title: "Vouchers",
    //   icon: "voucher",
    //   colour: "secondary",
    //   route: "/vouchers",
    // },
    {
      title: "More Info",
      icon: "information-circle",
      colour: "secondary",
      route: "/more-info",
      params: { title: "More Info" },
    },
    {
      title: "News & Alerts",
      icon: "news",
      colour: "secondary",
      route: "/news-and-alerts",
    },
  ],
};
