<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!iframeService.inIframe()"
        (click)="modalCtrl.dismiss()"
        [defaultHref]="defaultHref"
      ></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <img class="logo" alt="Logo" item-right src="./assets/images/logo.png" />
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar (ionInput)="getOptions($event)" animated></ion-searchbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item
      detail
      class="ion-activatable"
      *ngFor="let option of options"
      (click)="selectOption(option)"
    >
      {{ option.name }}
      <ion-ripple-effect></ion-ripple-effect>
    </ion-item>
  </ion-list>
</ion-content>
