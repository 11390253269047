import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppGuard } from "./app.guard";
import { ExternalRedirectComponent } from "./components/external-redirect/external-redirect.component";

const routes: Routes = [
  {
    path: "",
    canActivateChild: [AppGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/menu/menu.module").then((m) => m.MenuPageModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./pages/calendar/calendar.module").then(
            (m) => m.CalendarPageModule
          ),
      },
      {
        path: "address",
        loadChildren: () =>
          import("./pages/address/address.module").then(
            (m) => m.AddressPageModule
          ),
      },
      {
        path: "event-details/:id",
        loadChildren: () =>
          import("./pages/event-details/event-details.module").then(
            (m) => m.EventDetailsPageModule
          ),
      },
      {
        path: "bin-info",
        loadChildren: () =>
          import("./pages/info/info.module").then((m) => m.InfoPageModule),
      },
      {
        path: "more-info",
        loadChildren: () =>
          import("./pages/info/info.module").then((m) => m.InfoPageModule),
      },
      {
        path: "info/:id",
        loadChildren: () =>
          import("./pages/info/info.module").then((m) => m.InfoPageModule),
      },
      {
        path: "info-details/:id",
        loadChildren: () =>
          import("./pages/info-details/info-details.module").then(
            (m) => m.InfoDetailsPageModule
          ),
      },
      {
        path: "info-multi-map/:id",
        loadChildren: () =>
          import("./pages/info-multi-map/info-multi-map.module").then(
            (m) => m.InfoMultiMapPageModule
          ),
      },
      {
        path: "location/:id",
        loadChildren: () =>
          import(
            "./pages/info-multi-map-details/info-multi-map-details.module"
          ).then((m) => m.InfoMultiMapDetailsPageModule),
      },
      {
        path: "material-details/:id",
        loadChildren: () =>
          import("./pages/material-details/material-details.module").then(
            (m) => m.MaterialDetailsPageModule
          ),
      },
      {
        path: "a-z",
        loadChildren: () =>
          import("./pages/materials/materials.module").then(
            (m) => m.MaterialsPageModule
          ),
      },
      {
        path: "menu",
        loadChildren: () =>
          import("./pages/menu/menu.module").then((m) => m.MenuPageModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./pages/settings/settings.module").then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: "eula",
        loadChildren: () =>
          import("./pages/eula/eula.module").then((m) => m.EulaPageModule),
      },
      {
        path: "news-and-alerts",
        loadChildren: () =>
          import("./pages/news-and-alerts/news-and-alerts.module").then(
            (m) => m.NewsAndAlertsPageModule
          ),
      },
      {
        path: "news/:id",
        loadChildren: () =>
          import(
            "./pages/news-and-alerts/news/news-details/news-details.module"
          ).then((m) => m.NewsDetailsPageModule),
      },
      {
        path: "alerts/:id",
        loadChildren: () =>
          import(
            "./pages/news-and-alerts/alerts/alert-details/alert-details.module"
          ).then((m) => m.AlertDetailsPageModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./pages/forms/forms.module").then((m) => m.FormsPageModule),
      },
      // {
      //   path: "vouchers",
      //   loadChildren: () =>
      //     import("./pages/vouchers/vouchers.module").then(
      //       (m) => m.VouchersPageModule
      //     ),
      // },
      {
        path: "external-redirect",
        component: ExternalRedirectComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
